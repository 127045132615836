// Lazy Load

$j(function() {
	$j('img.kula-lazy:not([data-no-lazy])').lazy({
		combined: true,
		delay: 1500,
		effect: 'fadeIn',
		effectTime: 200,
		afterLoad: function(element) {
			element.removeClass('kula-lazy-is-loading');
		}
	});
});
