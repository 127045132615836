// Sitemap scripts
$j(function() {
	$j('ul .sitemap-toggle').click(function() {
		$j(this).siblings('.children').toggle();

		if ($j(this).hasClass('open')) {
			$j(this).removeClass('open');
		} else {
			$j(this).addClass('open');
		}
	});

	$j('#sitemap-title .sitemap-toggle').click(function() {
		if ($j(this).hasClass('open')) {
			$j('.children').hide();
			$j(this).removeClass('open');
			$j('ul .sitemap-toggle').removeClass('open');
		} else {
			$j('.children').show();
			$j(this).addClass('open');
			$j('ul .sitemap-toggle').addClass('open');
		}
	});

	$j('.sitemap-format-toggle').click(function() {
		if ($j('.sitemap').hasClass('horizontal')) {
			$j('.sitemap').removeClass('horizontal');
			$j(this).html('Display as Tree');
			$j('.sitemap .children').hide();
			$j('ul .sitemap-toggle, #sitemap-title .sitemap-toggle').removeClass('open');

		} else {
			$j('.sitemap').addClass('horizontal');
			$j(this).html('Display Stacked');
			$j('.sitemap .children').show();
		}
	});
});
