// Common Scripts

$j(function() {
	// Tables

	$j('.wp-block-table').wrap('<div class="table-overflow" />');

	function tableOverflow() {
		$j('.table-overflow').each(function() {
			var table_wrapper_width = $j(this).width(),
				table_width = $j(this).children('table').width();

			if (table_width > table_wrapper_width) {
				$j(this).addClass('has-overflow');
			} else {
				$j(this).removeClass('has-overflow');
			}
		});
	}

	tableOverflow();

	$j(window).resize(function() {
		tableOverflow();
	});

	function loadSVG($timer) {
		// Load SVG logo
		setTimeout(function() {
			var logo = $j('.logo--svg'),
				src = logo.data('logo-src');
			
			logo.attr('src', src);
			logo.css('visibility', 'visible');
		}, $timer);
	}

	loadSVG(200);
});

$j(window).load(function() {
	// Waypoint scroll functionality.

	var breakpoint = 767,
		width = window.innerWidth || $j(window).width();

	if (width > breakpoint) {
		$j('.build__shapes, .how__shapes, .get-started__shapes').waypoint(function(direction) {
			var element = this.element;

			if (direction === 'down') {
				$j(element).addClass('squeeze');
			}
		}, {
			offset: '80%'
		});
	}

	$j('.illustration').waypoint(function(direction) {
		var element = this.element;

		if (direction === 'down') {
			$j(element).addClass('animate');
		}
	}, {
		offset: '80%'
	});

	if (width > breakpoint) {

		// Down
		$j('#identity, #build, #who-its-for, #how-it-works, #pricing, #get-started').waypoint(function(direction) {
			var element = this.element.id,
				target = $j('a[href="/#' + element + '"]');

			if (direction === 'down') {
				if (element !== 'identity') {
					$j('.nav-menu a').removeClass('active');
					target.addClass('active');
				} else {
					$j('.nav-menu a').removeClass('active');
				}
			}
		}, {
			offset: 140
		});

		//Up
		$j('#identity, #build, #who-its-for, #how-it-works, #pricing, #get-started').waypoint(function(direction) {
			var element = this.element.id,
				target = $j('a[href="/#' + element + '"]');

			if (direction === 'up') {
				if (element !== 'identity') {
					$j('.nav-menu a').removeClass('active');
					target.addClass('active');
				} else {
					$j('.nav-menu a').removeClass('active');
				}
			}
		}, {
			offset: 100
		});
	}

	function set_width() {
		width = window.innerWidth || $j(window).width();
	}

	$j(window).resize(function() {
		debounce(set_width(), 200); //eslint-disable-line no-undef
	});

});
