// Sticky header

$j(function() {
	if ($j('.header--sticky').length) {
		var	header = $j('.header--sticky'),
			menu_logo = $j('.menu-logo__img');

		function checkStickyHeader() {
			var scroll_top = ($j('#wpadminbar').length) ? $j(window).scrollTop() + $j('#wpadminbar').height() : $j(window).scrollTop(),
				scrolling_start = ($j('#wpadminbar').length) ? 50 + $j('#wpadminbar').height() : 50,
				window_height = $j(window).height(),
				document_height = $j(document).height();

			if (scroll_top > scrolling_start) {
				header.addClass('header--is-scrolling');
				menu_logo.addClass('reveal');
			} else {
				header.removeClass('header--is-scrolling');
				menu_logo.removeClass('reveal');
			}

			if ((scroll_top + 150) + window_height >= document_height && scroll_top > 100) {
				header.addClass('header--is-hidden');
			} else {
				header.removeClass('header--is-hidden');
			}
		}

		checkStickyHeader();

		$j(window).resize(function() {
			debounce(checkStickyHeader(), 200);
		});

		$j(window).scroll(function() {
			debounce(checkStickyHeader(), 200);
		});
	}
});

// https://davidwalsh.name/javascript-debounce-function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func.apply(context, args);
		}
	};
};
