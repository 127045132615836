// Off-canvas nav

$j(function() {
	if ($j('.primary-menu--off-canvas').length) {
		var breakpoint = 768,
			primary_menu = $j('.primary-menu'),
			sub_navs = primary_menu.find('.sub-nav'),
			html_body = $j('html, body'),
			header = $j('.header');

		function reset() {
			$j('.menu-toggle').removeClass('menu-toggle--open');
			primary_menu.removeClass('primary-menu--show');
			sub_navs.removeClass('open').removeAttr('style');
			html_body.removeClass('no-scroll');
			header.removeClass('header--menu-open');
		}

		$j(document).on('click', '.menu-toggle', function() {
			if (!$j('.menu-toggle').hasClass('menu-toggle--open')) {
				html_body.addClass('no-scroll');
				header.addClass('header--menu-open');
			} else {
				html_body.removeClass('no-scroll');
				header.removeClass('header--menu-open');
			}
		});

		// Making necessary adjustments if the browsers is resized larger than breakpoint

		$j(window).resize(function() {
			if ($j(window).width() > breakpoint) {
				reset();
			}
		});

		// Closing menu if clicking outside

		$j(document).on('click', function(e) {
			if ($j(window).width() <= breakpoint) {
				if ($j(e.target).closest('.primary-menu').length === 0) {
					reset();
				}
			}
		});

		// Adding close button

		primary_menu.append('<a href="#" class="menu-toggle"><svg class="icon-menu"><use xlink:href="#icon-menu"></use></svg> ' + sync_translations.close_text + '</a>');
	}
});
