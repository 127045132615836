// Carousel JS - Using 'lightSlider' library

$j(function(){
	function lSnavCheck(element) {
		var parent = element.closest('.lSSlideOuter'),
			prev = parent.find('.lSPrev'),
			next = parent.find('.lSNext'),
			firstPager = parent.find('.lSPager').children('li').first(),
			lastPager = parent.find('.lSPager').children('li').last();

		if (firstPager.hasClass('active')) {
			prev.addClass('disabled');
		} else {
			prev.removeClass('disabled');
		}

		if (lastPager.hasClass('active')) {
			next.addClass('disabled');
		} else {
			next.removeClass('disabled');
		}
	}

	function lSonLoad(element) {
		var parent = element.closest('.lSSlideOuter'),
			slideWrapper = parent.find('.lSSlideWrapper'),
			prev = parent.find('.lSPrev'),
			next = parent.find('.lSNext');

		prev.attr('tabindex', '0');
		next.attr('tabindex', '0');

		prev.keyup(function(e) {
			if (e.keyCode == 13 || e.keyCode == 37) { // Enter or left arrow
				element.goToPrevSlide();
			} else if (e.keyCode == 39) { // Right arrow
				element.goToNextSlide();
			}
		});

		next.keyup(function(e) {
			if (e.keyCode == 13 || e.keyCode == 39) { // Enter or right arrow
				element.goToNextSlide();
			} else if (e.keyCode == 37) { // Left arrow
				element.goToPrevSlide();
			}
		});

		prev.on('mouseup touchend', function() {
			$j(this).blur();
		});

		next.on('mouseup touchend', function() {
			$j(this).blur();
		});

		parent.find('.lSAction').prependTo(slideWrapper);
	}

	$j(window).load(function(){
		$j('.slider').lightSlider({
			item: 1,
			loop: false,
			slideMargin: 10,
			keyPress: false,
			prevHtml: '<svg class="icon-arrow-left"><use xlink:href="#icon-arrow-left"></use></svg><span class="visuallyhidden lSPrev-text">' + sync_translations.carousel_prev_text + '</span>',
			nextHtml: '<svg class="icon-arrow-right"><use xlink:href="#icon-arrow-right"></use></svg><span class="visuallyhidden lSNext-text">' + sync_translations.carousel_next_text + '</span>',
			speed: 600,
			adaptiveHeight: true,
			onSliderLoad: function(element) {
				lSonLoad(element);
				lSnavCheck(element);
			},
			onAfterSlide: function(element) {
				lSnavCheck(element);
			}
		});
	});
});
