// Header scripts

$j(function() {
	var breakpoint = 767,
		width = window.innerWidth || $j(window).width(),
		overlay = $j('.menu-overlay'),
		menu_toggle = $j('.menu-toggle'),
		sub_menu_toggle = $j('.sub-menu-toggle'),
		primary_menu = $j('.primary-menu'),
		sub_navs = primary_menu.find('.sub-nav'),
		secondary_menu = $j('.secondary-menu'),
		secondary_menu_links = $j('.secondary-menu__links');

	// Mobile menu main toggle button
	$j(document).on('click', '.menu-toggle', function() {
		if (!menu_toggle.hasClass('menu-toggle--open')) {
			primary_menu.addClass('primary-menu--show');
			overlay.addClass('menu-overlay--open');
			$j('body, html').addClass('noscroll');
			menu_toggle.addClass('menu-toggle--open').find('svg use').attr('xlink:href', '#icon-skeleton-close');
		} else {
			primary_menu.removeClass('primary-menu--show');
			overlay.removeClass('menu-overlay--open');
			$j('body, html').removeClass('noscroll');
			menu_toggle.removeClass('menu-toggle--open').find('svg use').attr('xlink:href', '#icon-menu');
		}

		return false;
	});

	// Mobile Jump menu
	$j(document).on('click', '.home a', function(event) {
		var href = $j(this).attr('href');

		if (href.substring(0, 2) === '/#') {
			href = href.substring(1, href.length);
		}

		if (href.substring(0, 1) === '#' && $j(href).length) {
			event.preventDefault();

			// Detect if menu is open
			if (width < breakpoint && $j('.menu-toggle--open').length) {
				$j('.menu-toggle').trigger('click');
			}

			// Scroll to target.
			$j('html, body').animate({
				scrollTop: $j(href).offset().top - 120
			}, 500);
		}
	});

	// Mobile menu dropdown toggle buttons
	sub_menu_toggle.click(function() {
		if (width > breakpoint) {
			return false;
		} else if (!$j(this).hasClass('toggle-open')) {
			close_section($j(this).parent().siblings('li').find('.sub-menu-toggle'));
			$j(this).removeClass('closed').addClass('toggle-open').attr('aria-expanded', 'true').siblings('.sub-nav').slideDown('fast', 'swing');
		} else {
			close_section($j(this));
		}

		return false;
	});

	function close_section(sub_menu_toggles) {
		sub_menu_toggles.removeClass('toggle-open').addClass('closed').attr('aria-expanded', 'false');
		sub_menu_toggles.siblings('.sub-nav').slideUp('fast', 'swing');
		var sub_buttons = sub_menu_toggles.siblings('.sub-nav').find('.sub-menu-toggle.toggle-open');

		if (sub_buttons.length) {
			close_section(sub_buttons);
		}
	}

	// Blur after click

	$j('.menu-toggle, .sub-menu-toggle, .primary-menu a, .secondary-menu a').on('mouseup touchend', function() {
		$j(this).blur();
	});

	// Making necessary adjustments if the browsers is resized larger than breakpoint

	$j(window).resize(function() {
		width = window.innerWidth || $j(window).width();

		if (width > breakpoint) {
			menu_toggle.removeClass('menu-toggle--open');
			primary_menu.removeClass('primary-menu--show');
			sub_navs.removeClass('open').removeAttr('style');
			overlay.removeClass('menu-overlay--open');
			$j('body, html').removeClass('noscroll');

			if ($j('.secondary-menu').length) {
				secondary_menu_links.detach();
				secondary_menu.append(secondary_menu_links);
			}
		} else {
			if ($j('.secondary-menu').length) { // eslint-disable-line no-lonely-if
				secondary_menu_links.detach();
				primary_menu.append(secondary_menu_links);
			}
		}
	});

	// Initializing a11y menu, only if .primary-menu is visible

	function menuInit() {
		$j('.primary-menu:visible').accessibleMegaMenu({
			/* prefix for generated unique id attributes, which are required
			   to indicate aria-owns, aria-controls and aria-labelledby */
			uuidPrefix: 'accessible-megamenu',

			/* css class used to define the megamenu styling */
			menuClass: 'nav-menu',

			/* css class for a top-level navigation item in the megamenu */
			topNavItemClass: 'nav-item',

			/* css class for a megamenu panel */
			panelClass: 'sub-nav',

			/* css class for a group of items within a megamenu panel */
			panelGroupClass: 'sub-nav-group',

			/* css class for the hover state */
			hoverClass: 'hover',

			/* css class for the focus state */
			focusClass: 'focus',

			/* css class for the open state */
			openClass: 'open',

			/* for determining the direction of the menu */
			menuDirection: 'horizontal'
		}).addClass('menu-init');
	}

	menuInit();

	// If .primary-menu wasn't visible and browser is sized up, initialize a11y menu function once

	$j(window).resize(function() {
		if (!$j('.primary-menu').hasClass('menu-init')) {
			menuInit();
		}
	});

	// KB functions for displaying nested dropdowns only when above breakpoint

	if (width > breakpoint) {
		$j('.sub-nav-0 a').focus(function() {
			$j('.sub-nav-0 .sub-nav, .sub-nav a, .sub-nav-0 li').removeClass('sub-open');
			$j(this).parents('.sub-nav:not(.sub-nav-0)').addClass('sub-open');
			$j(this).siblings('.sub-nav').addClass('sub-open');
			$j(this).parents('.sub-nav:not(.sub-nav-0)').siblings('a').addClass('sub-open').parent().addClass('sub-open');
		});

		$j('.sub-nav-0 a').keydown(function(e) {
			var keycode = e.which ? e.which : e.keyCode;

			if (keycode == 27) { // ESC key
				$j(this).removeClass('sub-open').parents().removeClass('sub-open');
				$j('.sub-nav-0 .sub-nav').removeClass('sub-open');
			}
		});
	}
});
